/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { IMemberDetails } from '../../../modules/management/members/components/membersModal'
import { fetchMemberLoans, fetchMemberDetails } from '../../../modules/management/requests/_request'
import { KTIcon } from '../../../../_metronic/helpers'
import moment from 'moment'

type Props = {
  className: string
}

const MemberLoanList: React.FC<Props> = ({ className }) => {
  const [members, setMembers] = useState<any>([]);

  useEffect(() => {
    // Fetch members from the backend and update the state
    getMemberDetails();
  }, []);

  const getMemberDetails = async () => {
    try {
      const data = await fetchMemberDetails()
      setMembers(data)
    } catch (error) {
      console.error(error)
    }
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        {/*begin::Search*/}
        <div className="d-flex align-items-center position-relative my-1">
          <h3>Loan List</h3>
        </div>
        {/*end::Search*/}
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <Link
            to='/management/members/add-new'
            className='btn btn-sm btn-light-primary'
          // data-bs-toggle='modal'
          // data-bs-target='#kt_modal_invite_friends'
          >
            <KTIcon iconName='plus' className='fs-3' />
            Add New Member
          </Link>
        </div>
      </div>

      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Member Name</th>
                <th className='min-w-120px'>Group Name</th>
                <th className='min-w-120px'>Contact</th>
                <th className='min-w-120px'>Loan Amount</th>
                <th className='min-w-120px'>Loan Period</th>
                <th className='min-w-120px'>Loan Type</th>
                <th className='min-w-120px'>Date Taken</th>
                <th className='min-w-120px'>Interest</th>
                <th className='min-w-120px'>Due Amount</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {members.map((member: any) => (
                <tr key={member.memberName}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fs-6'>
                          {member.memberName}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fs-6'>
                          {member.groupName}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fs-6'>
                          {member.phoneNumber}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fs-6'>
                          {member.loanAmount}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fs-6'>
                          {member.loanPeriod} Weeks
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fs-6'>
                          {member.loanType}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fs-6'>
                          {moment(member.date).format('DD/MM/YYYY')}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fs-6'>
                          {member.dueAmount}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fs-6'>
                          {moment(member.dueDate).format('DD/MM/YYYY')}
                        </span>
                      </div>
                    </div>
                  </td>
                  {/* <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTIcon iconName='switch' className='fs-3' />
                      </a>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </a>
                      <a
                        href='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      >
                        <KTIcon iconName='trash' className='fs-3' />
                      </a>
                    </div>
                  </td> */}
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
    </div>
  )
}

export default MemberLoanList
