import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
// Members
export const REGISTER_MEMBER = `${API_URL}/member/add-member`
export const GET_MEMBERS = `${API_URL}/member/get-members`
export const GET_MEMBER_NAMES = `${API_URL}/member/get-member-names`
export const GET_MEMBER_DETAILS = `${API_URL}/member/member-details`

// Loans
export const CREATE_LOAN = `${API_URL}/loan/create-loan`
export const GET_MEMBER_LOANS = `${API_URL}/loan/get-loans`

// register member
export function registerMember(
  fName: string,
  lName: string,
  group: string,
  contactPhone: string,
  district: string,
  ninNumber: string,
  area: string,
  loanOfficer: string,
  nextOfKin: string,
  sourceOfIncome: string,
  loanType: string
) {
  return axios.post(REGISTER_MEMBER, {
    fName,
    lName,
    group,
    contactPhone,
    district,
    ninNumber,
    area,
    loanOfficer,
    nextOfKin,
    sourceOfIncome,
    loanType
  })
}

// fetch members
export function fetchMember() {
  return axios.get(GET_MEMBERS)
    .then(res => {
      return res.data
    });
}

export function fetchMemberNames() {
  return axios.get(GET_MEMBER_NAMES)
    .then(res => {
      return res.data
    });
}

export function fetchMemberDetails() {
  return axios.get(GET_MEMBER_DETAILS)
    .then(res => {
      console.log(res.data)
      return res.data
    });
}

// Loans
export function createNewLoan(
  memberId: number,
  name: string,
  group: string,
  amount: number,
  period: number,
  loanType: string,
  interest: number
) {
  return axios.post(CREATE_LOAN, {
    memberId,
    name,
    group,
    amount,
    period,
    loanType,
    interest
  })
}

export function fetchMemberLoans() {
  return axios.get(GET_MEMBER_LOANS)
    .then(res => {
      return res.data
    });
}



