/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  StatisticsWidget5,
} from '../../../_metronic/partials/widgets'
import MemberLoanList from './components/dashboardTable'

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-3'>
        <StatisticsWidget5
          className='card-xl-stretch mb-xl-8'
          svgIcon='chart-simple'
          color='white'
          iconColor='primary'
          title='120 | 13'
          description='Members | Loans'
        />
      </div>

      <div className='col-xl-3'>
        <StatisticsWidget5
          className='card-xl-stretch mb-xl-8'
          svgIcon='cheque'
          color='dark'
          iconColor='white'
          title='13 | Ugx 1,500,000'
          titleColor='white'
          description='Active Loans'
          descriptionColor='white'
        />
      </div>

      <div className='col-xl-3'>
        <StatisticsWidget5
          className='card-xl-stretch mb-5 mb-xl-8'
          svgIcon='chart-pie-simple'
          color='info'
          iconColor='white'
          title='13 | Ugx 400,000'
          titleColor='white'
          description='Overdue Loans'
          descriptionColor='white'
        />
      </div>

      <div className='col-xl-3'>
        {/*pending loan applications, thinking of using this to also show pending new member details.*/}
        <StatisticsWidget5
          className='card-xl-stretch mb-xl-8'
          svgIcon='briefcase'
          color='primary'
          iconColor='white'
          title='4'
          titleColor='white'
          description='Pending Application'
          descriptionColor='white'
        />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='card mb-5 mb-xl-8 mt-4'>
      <MemberLoanList className='card-xxl-stretch mb-5 mb-xl-8' />
    </div>
    {/* end::Row */}
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
      {/* <MemberLoanList className='card-xl-stretch mb-5 mb-xl-8' /> */}
    </>
  )
}

export { DashboardWrapper }
