/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTIcon } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title='Dashboard'
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Management</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/management/members'
        title='Members'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItem to='/management/members/list' title='List' hasBullet={true} />
        <SidebarMenuItem to='/management/members/add-new' title='Add new' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/management/loans'
        title='Loans'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItem to='/management/loans/active' title='Active Loans' hasBullet={true} />
        <SidebarMenuItem to='/management/loans/applications' title='Loan Applications' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/management/accounts'
        title='Account'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/management/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/management/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Others</span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to='/others/settings'
        title='Settings'
        icon='profile-circle'
        fontIcon='bi-gear'
      >
        <SidebarMenuItem to='/others/settings/users' title='Users & Roles' hasBullet={true} />
        {/* <SidebarMenuItem to='/others/settings/' title='General Settings' hasBullet={true} /> */}
      </SidebarMenuItemWithSub>
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href='/'
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Support {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export { SidebarMenuMain }
